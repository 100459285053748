<template>
  <div v-if="showPopupBox" class="modal d-block modal-background" tabindex="-1">
    <div class="modal-dialog popup-style">
      <div class="modal-content">
        <div class="modal-header border-0 align-items-start pb-0">
          <h1 class="modal-title item-secondary-title text-center w-100 pt-3" v-html="title" />
          <button
            type="button"
            class="btn-close btn-dismiss btn-round"
            @click="closePopupBoxOnSecondaryBtn" />
        </div>
        <div class="modal-body p-4 pt-2 pb-3">
          <div class="text-center item-secondary-title item-normal" v-html="content" />
        </div>
        <div class="modal-footer justify-content-center border-0 pt-0 mb-3">
          <button
            v-if="secondaryText"
            type="button"
            class="btn btn-booster-secondary btn-foot float-start"
            :class="{'col': secondaryText}"
            @click="closePopupBoxOnSecondaryBtn"
            v-html="secondaryText" />
          <button
            type="button"
            class="btn btn-booster-primary btn-foot"
            :class="{'col': secondaryText, 'float-end': secondaryText}"
            @click="closePopupBox"
            v-html="okay" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PopupBox",
  props: {
    title: {type: String, default: ""},
    content: {type: String, default: ""},
    okay: {type: String, default: "Okay"},
    secondaryText: {type: String, default: ""},
    showPopupBox: {type: Boolean, default: false}
  },
  emits: ["update:showPopupBox", "primaryBtnAction"],
  methods: {
    closePopupBox() {
      this.$emit("primaryBtnAction");
      this.$emit("update:showPopupBox", false);
    },
    closePopupBoxOnSecondaryBtn() {
      this.$emit("update:showPopupBox", false);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/shared-styles.scss";

.btn-dismiss {
  position: absolute;
  right: 1rem;
}

.popup-style {
  width: 20.938rem;
  padding-top: 10rem;
}

.modal-background {
  background:rgba(0, 0, 0, 0.50);
}
</style>
