import { defineStore } from "pinia";

export const userStore = defineStore("user", {
  state: () => {
    return {
      userInfo: {
        adviserId: 0,
        name: "",
      },
    };
  },
  actions: {
    setUserName(name: string) {
      this.userInfo.name = name;
    },
    setAdviserId(id: number) {
      this.userInfo.adviserId = id;
    },
  },
});
