import { defineStore } from "pinia";

export const popupStore = defineStore("popup", {
  state: () => {
    return {
      popupState: {
        title: "",
        content: "",
        secondaryText: "",
        okay: "Okay",
        showPopupBox: false,
        primaryActionBtn: {},
      },
    };
  },
  actions: {
    showMsg(msg: string) {
      this.popupState.content = msg;
      this.popupState.showPopupBox = true;
    },
  },
});
